import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo"
import Latest from "../components/latest"
import Rightcol from "../components/rightcol"

const NewManga = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Latest Manga" />
      <div className="flex-grow lg:flex border-t-4 border-pink-500 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto ">
        <div className="min-w-0 flex-1 bg-white xl:flex">
         
          <div className="bg-transparent lg:min-w-0 lg:flex-1">
            <div className="border-b border-t border-gray-200 p-2 xl:border-t-0">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
                </svg>

                <h1 className="text-lg font-medium pl-2">NEW MANGA</h1>
              </div>
            </div>

            <div className="pl-2 pr-2 pt-4 pb-4   xl:border-t-0  xl:pt-6" isHomePage>
              <div className="flex items-center">
              <Latest/>
              </div>
            </div>
          </div>
        </div>
        <Rightcol/>
      </div>
    </Layout>
  )
}

export default NewManga

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
